import Link from 'next/link'
import Image from 'next/image'
import Navbar from '@/components/marketing/navbar/navbar.js'
import { useTranslation } from 'react-i18next'

const NotFoundPage = () => {
  const { t } = useTranslation(['tests', 'common'])
  return (
    <>
      <Navbar />
      <div className='not-found-container'>
        <figure className='not-found-bg'>
          <Image src='/images/marketing/hero-bg.svg' layout='fill' alt='' />
        </figure>
        <div className='not-found-content'>
          <span className='not-found-subhead'>
            {t('common:somethingWrong')}
          </span>
          <h1 className='not-found-title intro tc'>
            {t('common:404.heading')}
          </h1>
          <p className='centered not-found-caption'>
            {t('common:404.body')}
            <a href='mailto:support@sigmaassessments.com'>
              support@SigmaAssessments.com
            </a>
          </p>
          <div>
            <Link href='/'>
              <a className='btn btn-secondary'>{t('common:404.homeButton')}</a>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
